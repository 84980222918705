import React from "react"
import Page from "./../templates/page.js"
import ProjectList from "./../components/project-list.js"
import { Helmet } from 'react-helmet'

import { Icon, Hackaday } from "./../components/helpers.js"
import { FaGithub } from "react-icons/fa";

export default ({ pageContext }) => {

    var projects;
    const data = pageContext.projects;

    //sort projects by date
    data.sort(function (a, b) {
        
        return new Date(b.logs[b.logs.length-1].date) - new Date(a.logs[a.logs.length-1].date);
    });

    for (var i=0; i<data.length; i++)
    {
        projects = <>{projects}<ProjectList data={pageContext.projects[i]} /></>
    }

    const hero = <div><h1>Hello!</h1><p>I am an engineer by day, maker by night, who likes reinventing the wheel.</p></div>;
    
    const main = <>     
        <h2>Recent projects</h2>
        {projects}
        </>;

    pageContext.main = main;

    const side = <div className="about"><h2>About</h2>

        <p>After finishing various projects such as a drawbot, a drone, a CAN-bus display, and a WiFi counter, I created this website to document my future projects.</p>

        <p>As a maker, I always start from scratch, and work towards a fully designed 'product'. I like learning new things, and I hope that sharing my work inspires you to do the same. </p>

        </div>;

    return (
        <>
            <Helmet>
                <title>Maakbaas</title>
                <meta name="description" content="I am an engineer by day, maker by night, who likes reinventing the wheel. I like learning new things, and created this website to document my ongoing projects." />
            </Helmet>
            <Page heroJSX={hero} content={main} 
                sidebar={side}
                sidebar2={<p><a href="https://hackaday.io/maakbaas" target="_blank" rel="noopener noreferrer"><Icon><Hackaday /></Icon> Hackaday.io</a><br />
                    <a href="https://github.com/maakbaas" target="_blank" rel="noopener noreferrer"><Icon><FaGithub /></Icon> Github</a></p>} />
        </>);

}